<template>
    <div class="cs-block cs-blog-post-preview" :class="`${styleClass}${hasBg}`">
        <div class="cs-block-base">
            <div class="container">
                <CSBlogPostPreview :data="data" />
            </div>
        </div>
    </div>
</template>

<script>

import {computed} from '../libs/common-fn';

export default {
	name: "BlogPostPreview",
	components: {
        ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
        PostCards: () => import("../components/CSUtilsPostCards.vue"),
		CSBlogPostPreview: () => import("../components/CSBlogPostPreview.vue"),
    },
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			cards: [],
		}
	},
	computed: {
		...computed('BlogPostPreview'),
		hasBg() {
			if (this.data.style == 'color') return true;

			return false;
		}
	},
	methods: {
		cardShortText(title, maxLength) {
			const shortenedText =
				title.substring(0, maxLength) + (title.length > maxLength ? "..." : "")
			return shortenedText
		},
		convertDate() {
			const date = new Date()
			let day = date.getDate()
			if (day < 10) day = `0${day}`
			let month = date.getMonth() + 1
			if (month < 10) month = `0${month}`

			const year = date.getFullYear()
			return `${day}.${month}.${year}`
		},
	},
	mounted() {
		console.log(this)
	},
}
</script>

<style lang="scss" scoped>
@import "../styles/main.scss";
.cs-blog-post-preview {
	&.cs-style- {
        &color {
			::v-deep {
				@include cs-block-color;
				.cs-title {
					color: white
				}
				.cs-sub-title {
					color: $sub_title_color;
				}

				.cs-text {
					color: $text_color_over_theme_color;

					::v-deep {
						* {
							color: $text_color_over_theme_color !important;
						}
					}
				}
			}
        }

        &light {
			::v-deep {
				@include cs-block-light;

				.cs-sub-title {
					color: $sub_title_color_dark;
				}
			}
        }

        &dark {
			::v-deep {
				@include cs-block-dark;
				.cs-sub-title {
					color: $sub_title_color_dark;
				}
			}
        }
    }
	::v-deep {
		.header-content {
			text-align: center;
			margin-bottom: $header_margin;
			&:last-child {
				margin-bottom: 0;
			}
			.cs-title, .cs-sub-title {
				margin-bottom: 20px;
			}
			.cs-text {
				::v-deep {
					* {
						text-align: center;
					}
					img {
						max-width: 100%;
						height: 300px;
						border-radius: 10px;
						margin: 10px;
					}
					p,
					span {
						@include text;
					}
					*:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
		&-list {
			row-gap: 30px;
		}
		&-card {
			border: 1px solid black;
			border-radius: 20px;
			flex: 1 1 auto;
			overflow: hidden;
			img {
				width: 100%;
				border-top-left-radius: 20px !important;
				border-top-right-radius: 20px !important;
				height: 180px;
				object-fit: cover;
				object-position: contain;
			}

			&-info {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin: 15px;
				.button {
					width: 100%;
					padding: 15px 0;
					a {
						@include theme-button;
						padding: 10px 20px;
					}
				}
			}

			&-title {
				width: 100%;
				@include title;
				font-size: $regular_text_size;
				font-family: Italiana, sans-serif;
				color: #333333;
				margin-bottom: 5px;
				@media (max-width: 991px) {
					font-size: 20px;
				}
			}

			&-text {
				width: 100%;
				@include text;
				margin-bottom: $theme_items_margin;
			}
		}
	}
}

::v-deep {
	.author-info {
		width: 100%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 20px;
		.author,
		.date {
			@include text;
			color: $labels_color;
			font-size: 12px;
		}
	}

	a {
		text-decoration: none;
	}
}
</style>
